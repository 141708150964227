<template>
  <div class="MembersChat">
    <Chat ref="chat" v-on:send-message="handleSendMessage" :messages="communityChatMessages"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import {USER_FETCH_PROFILE} from '@/store/user.module';
import {COMMUNITY_FETCH_MEMBERS} from '@/store/community/members.module';
import {
  COMMUNITY_FETCH_CHAT_MESSAGES,
  COMMUNITY_ADD_CHAT_MESSAGE,
  COMMUNITY_SEE_CHAT_MESSAGES,
  COMMUNITY_SET_CHAT_USER_UNSEEN_UPDATE,
} from '@/store/community/chat.module';
import store from '@/store'

import Chat from '@/components/Activity/Chat.vue';

export default {
  name: 'ChatPageMember',

  components: {
    Chat,
  },

  computed: {
    ...mapGetters([
      'origin',
      'userProfile',
      'isModerator',
      'isObservator',
      'isUser',
      'isClient',
      'communityId',
      'communityMembers',
      'communityMembersCount',
      'communityTotalNotSeen',
      'communitySelectedChat',
      'communityChatMessages',
      'theme',
    ])
  },

  methods: {

    openChat: async function(){
      await store.dispatch(COMMUNITY_SET_CHAT_USER_UNSEEN_UPDATE, {
        totalNotSeen:this.communityTotalNotSeen,
        delete: true,
        update: false,
      })

      console.log(this.communitySelectedChat)
      await store.dispatch(COMMUNITY_SEE_CHAT_MESSAGES, {
        userId:this.communitySelectedChat,
        messageIds:_.map(this.communityChatMessages, function (o, i) { return o.identifier })
      })
      if(this.$refs.chat != undefined) this.$refs.chat.adjustMessagesScroll()
    },

    handleSendMessage: async function(e){
      this.$emit('send-message',e)
      await store.dispatch(COMMUNITY_ADD_CHAT_MESSAGE, {
        message: e.message,
        file: e.file,
        video:e.video,
        image:e.image,
        userId:this.communitySelectedChat,
      })
    },

    fetchChatMessages: async function(){
    await store.dispatch(COMMUNITY_FETCH_CHAT_MESSAGES,{
        userId:this.communitySelectedChat
      })
      .then((data) => {
        this.openChat()
      })
    },
  },

  data ( ) {
    return {
    }
  },

  async mounted(){
    await this.fetchChatMessages()
  },
}
</script>

<style lang="scss">
.MembersChat{
  .ChatVideo{
    position: relative;
    &--Messages{
      height: calc(100vh - 222px)!important;
    }
  }
}
</style>
